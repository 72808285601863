import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Alert from '../../components/Alert/alert';
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/004/effective-titles.svg';
import SimpleTitleChanger from '../../components/PostExtras/SimpleTitleChanger/simple-title-changer';
import ExportTitleChanger from '../../components/PostExtras/ExportTitleChanger/export-title-changer';
import MusicTitleChanger from '../../components/PostExtras/MusicTitleChanger/music-title-changer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#30cfd0" gradientTo="#330867" mdxType="HeroPost">
  <img src={heroImg} alt="Titles can do more than you think" height="260" />
    </HeroPost>
    <h1>{`Effective titles and more`}</h1>
    <Date date="November 06, 2020" dateMachine="2020-11-06" mdxType="Date" />
    <p>{`This is not an SEO guide on how to write effective titles or (maybe I should say) how to create clickbait.`}<br />{`
No. We are going to talk about the technicals. If you want my writing advice on that I'd say a cliché:`}</p>
    <ul>
      <li parentName="ul">{`title should be short and descriptive`}</li>
    </ul>
    <p>{`Did mine do a good job? I don't know, but if you are here reading this, maybe it did.`}</p>
    <h2>{`Site name`}</h2>
    <p>{`I believe you should put the site name into the page's title because that's going to land as a name of a bookmark if someone happens to
add your page to bookmarks (do people still do that?):`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "692px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/566e3234699a72be22b41c2fc28dc94e/91e7e/add-to-bookmark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.83333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACP0lEQVQ4y42TyW7TUBSG7+Ow4JFYsOAxkKiEkApSWSCWiA1rUKFQOlCmVhVCpGRoKrW0JE3sNE3r2PEU27H9ca6TEIFSwbV+nemec89k5QQhBfxgPqb2ObDF7kcR+8ctlj/VCIVXfdfDGrjM6AxavnAcLq/Ahe1gy73qUZO13TqdSx/ly0tRHOMHAeFwSBCGaJ2Wh/Jiluek2dXQ9lzguQ6eZKw6Z10s26ZcrXF88pP6wQGHR0fs1w8wTBN9Cqd/fMlohD0YoExx0g7TM8ogETHVvNAkm+j+wmgKuRMmEIuDI21Qug+u53HSaNCXTN0o53PNZOXrKRuVLm9Lbd7umayXuwW07t3+peiNAm9KJo2eDCeGc8tCLezsEidJkW4URzK1nMNOQKU5oNbyqJ5K04XXVMsadSMc6wVlsZl2IiXD428l1HqlVtQ/hS5/JDTPM/73xDqanIWPO6h2oynT8fkhAzHMDrqbLcOg2WpJcK6cbpxmBZI0x/ECJAPubW2jdKBsko0OoDMcr0r2my/kKfQl8rmZ3l39gOqe9xil6Ww1Jo5jefYAE5umTpjxuhrxsjzkVWXIi72QngeLK1sovYxZluJFGd9bCYad/pGhhMTyMyrtpEAsJZaaMeqmybXbPa7f6aFutHm6M+TRyibqrHs+caa4nGSz7JgUpzPWvYpGY23XzVhc81naCHi46XN/fYjhwtLzDZSj/1/ZRatvF3vYF2rNQWGzx7zj2CShQxTYpJHL6vYXniy/59aDZ/wCfMUgQdbsu80AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bookmark added with a clear indication of a site",
            "title": "Bookmark added with a clear indication of a site",
            "src": "/static/566e3234699a72be22b41c2fc28dc94e/91e7e/add-to-bookmark.png",
            "srcSet": ["/static/566e3234699a72be22b41c2fc28dc94e/8ff5a/add-to-bookmark.png 240w", "/static/566e3234699a72be22b41c2fc28dc94e/e85cb/add-to-bookmark.png 480w", "/static/566e3234699a72be22b41c2fc28dc94e/91e7e/add-to-bookmark.png 692w"],
            "sizes": "(max-width: 692px) 100vw, 692px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Bookmark added with a clear indication of a site`}</figcaption>{`
  `}</figure>
    <p>{`And will help in fast identification. I see that useful also in the History tool of your browser of choice:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ff93a31b8d3c7877cb44caa1a6baad7b/5dded/history-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB7klEQVQoz3VT2W7bMBDU//9TH4oATYEmQVo0ceJDtuVLt0hKskRd0921KRQFSmBA7oo7nB1Snrk2KJsWum5wW9s5rm2Pph+gygqHMMd7UkNVVyilYSqNKC0QJgWiUwB/tUBF9V7XdzBGY5pGjCMV6wJ1XQGYEAQ7fC4/iCCnwzokcQJrLXiM04RuGNGPI+0EriSi63t4Tz8DPD77+PG6Fzw+b/D9xQfnv35b4MvDb8kpbXAJL4QQ2hj0Q4+/hzalHOK9/NriY5MIFqtI8C4I8enf8q9vB7wtloQFNv4Wy9Ua640Pf7uT2Pd3WK03svbqWiPLU2mVGsH/RpwkQng6X1BWlShmpaYsRV1EduSFglfQxv3hiH1wwPF0RhjHOJxOqMlg9saSL1V9lZhJ27uHEJchvvGwXSexd4wzMj9AkqZyQl4UpDjHMI5zEa9vSozkOGYwCef7YRDVtqNLOZ4V4jiSFkxpaNaykQv4dDaawW3xN3fDjOkfpVzjXVSLPM8Qk0JuiZHIOp1P5+KyqkU9x9OdxAF3a4RQXS0R5iiUQtNa8ahpW5mdCqcwzTL55tTzmsFvkFuWd3jODCJ6W+dLSDcVywbXFhe62dDfwoROEedZLZMwZkL2q7XdrKzrh5nEEXJhobRcFu9z4FoH19UfKu6X1eoM6C0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The site name is prominent on a History list",
            "title": "The site name is prominent on a History list",
            "src": "/static/ff93a31b8d3c7877cb44caa1a6baad7b/d9199/history-list.png",
            "srcSet": ["/static/ff93a31b8d3c7877cb44caa1a6baad7b/8ff5a/history-list.png 240w", "/static/ff93a31b8d3c7877cb44caa1a6baad7b/e85cb/history-list.png 480w", "/static/ff93a31b8d3c7877cb44caa1a6baad7b/d9199/history-list.png 960w", "/static/ff93a31b8d3c7877cb44caa1a6baad7b/07a9c/history-list.png 1440w", "/static/ff93a31b8d3c7877cb44caa1a6baad7b/5dded/history-list.png 1588w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`The site name is prominent on a History list`}</figcaption>{`
  `}</figure>
    <p>{`Now, should you put the site name before or after the title for the content of the page?`}<br />{`
Well, I believe `}<strong parentName="p">{`after`}</strong>{` is the way to go. Again, easy identification comes into play. If everything starts with "Tomek Dev" then
you have to omit it to understand what's this page about. Take a look at this:`}</p>
    <ul>
      <li parentName="ul">{`Tomek Dev - Effective titles`}</li>
      <li parentName="ul">{`Tomek Dev - If there is an input, there should be a form`}</li>
      <li parentName="ul">{`Tomek Dev - Content Curation is going to be a job and how Content Marketing killed the web`}</li>
      <li parentName="ul">{`Tomek Dev - Dynamic partiallyActive Link in Gatsby`}</li>
    </ul>
    <p>{`Doesn't look that bad when I have it on a list, right? Right. But consider the real estate of a browser's toolbar:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ea32f3e18777995abed8310e9fe81169/5d6a0/tabs-before.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAT0lEQVQI1yXKsQqAMAxFUf//7yqpTUKjRXCwlUgXeZsWHO5w4E7eHLU2NL/hveOsF4gYMyuiGpIVSMoIUUCSseQNqitoOKT/YSvP6LX9kA9nwEddR0rIOAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tabs are packed. Redundant information doesn't help here",
            "title": "Tabs are packed. Redundant information doesn't help here",
            "src": "/static/ea32f3e18777995abed8310e9fe81169/d9199/tabs-before.png",
            "srcSet": ["/static/ea32f3e18777995abed8310e9fe81169/8ff5a/tabs-before.png 240w", "/static/ea32f3e18777995abed8310e9fe81169/e85cb/tabs-before.png 480w", "/static/ea32f3e18777995abed8310e9fe81169/d9199/tabs-before.png 960w", "/static/ea32f3e18777995abed8310e9fe81169/07a9c/tabs-before.png 1440w", "/static/ea32f3e18777995abed8310e9fe81169/5d6a0/tabs-before.png 1580w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Tabs are packed. Redundant information doesn't help here`}</figcaption>{`
  `}</figure>
    <p>{`There is no lot of place but it can be even less if you use a lot of tabs. Having a title of your content first and the site name
second will help in identification. The content title is more important for a user:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/032ba4d20661b8499822410ee2e3b5e4/5d6a0/tabs-after.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAS0lEQVQI1xWKSQ6AIBDA/P/vRHZQiIkHwSXhA3U89NCm09Nuer84hXcMmrgxAeUTWvBbJbiMshHlIjYXYipoeWYbWP5nrTjp637wAXwCR/ZWghhkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tabs are still packed but more valuable information gets first",
            "title": "Tabs are still packed but more valuable information gets first",
            "src": "/static/032ba4d20661b8499822410ee2e3b5e4/d9199/tabs-after.png",
            "srcSet": ["/static/032ba4d20661b8499822410ee2e3b5e4/8ff5a/tabs-after.png 240w", "/static/032ba4d20661b8499822410ee2e3b5e4/e85cb/tabs-after.png 480w", "/static/032ba4d20661b8499822410ee2e3b5e4/d9199/tabs-after.png 960w", "/static/032ba4d20661b8499822410ee2e3b5e4/07a9c/tabs-after.png 1440w", "/static/032ba4d20661b8499822410ee2e3b5e4/5d6a0/tabs-after.png 1580w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Tabs are still packed but more valuable information gets first`}</figcaption>{`
  `}</figure>
    <h3>{`What separator to use`}</h3>
    <p>{`I'm using a pipe - `}<inlineCode parentName="p">{`|`}</inlineCode>{` because my dad is a plumber and I spend a lot of my life in the terminal. What else could I use? 😉`}<br />{`
Jokes aside, that could be a branding thing. Go crazy if that's your thing:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d91c24d55bd34a87e80670460a70aafa/5d6a0/tabs-crazy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAANklEQVQI12N49/rt/zdv3v5//e7d/1dA+sWr1/8/fPr8//6TZ/9v3X/0/+6jp//vPHwCponBALTzSYWYE+Q7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Here ♣ separates content title and the site name",
            "title": "Here ♣ separates content title and the site name",
            "src": "/static/d91c24d55bd34a87e80670460a70aafa/d9199/tabs-crazy.png",
            "srcSet": ["/static/d91c24d55bd34a87e80670460a70aafa/8ff5a/tabs-crazy.png 240w", "/static/d91c24d55bd34a87e80670460a70aafa/e85cb/tabs-crazy.png 480w", "/static/d91c24d55bd34a87e80670460a70aafa/d9199/tabs-crazy.png 960w", "/static/d91c24d55bd34a87e80670460a70aafa/07a9c/tabs-crazy.png 1440w", "/static/d91c24d55bd34a87e80670460a70aafa/5d6a0/tabs-crazy.png 1580w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Here ♣ separates content title and the site name`}</figcaption>{`
  `}</figure>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a3e3421252ed05c271cb19f2a3e5a69/0bdcb/tabs-crazy-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAOElEQVQI12N4//rd/1ev3vx//e79/9dv3/1/8fr1//cfP/1/8OTZ/1v3H/2/++jp/zsPn4BpYjAAtO5JhTvtvWkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Legal advice page with § as a separator? Why not",
            "title": "Legal advice page with § as a separator? Why not",
            "src": "/static/6a3e3421252ed05c271cb19f2a3e5a69/d9199/tabs-crazy-2.png",
            "srcSet": ["/static/6a3e3421252ed05c271cb19f2a3e5a69/8ff5a/tabs-crazy-2.png 240w", "/static/6a3e3421252ed05c271cb19f2a3e5a69/e85cb/tabs-crazy-2.png 480w", "/static/6a3e3421252ed05c271cb19f2a3e5a69/d9199/tabs-crazy-2.png 960w", "/static/6a3e3421252ed05c271cb19f2a3e5a69/07a9c/tabs-crazy-2.png 1440w", "/static/6a3e3421252ed05c271cb19f2a3e5a69/0bdcb/tabs-crazy-2.png 1579w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Legal advice page with § as a separator? Why not`}</figcaption>{`
  `}</figure>
    <p>{`After all, the worst you can do is to have a single title for your entire application. `}<strong parentName="p">{`Don't do that`}</strong>{`.`}<br />{`
Even if your app is just a tool, think for a while if you don't have some logical modules there. Because know what? 👇`}</p>
    <h2>{`Title can be changed dynamically`}</h2>
    <p>{`That's good news (not only) for Single Page Applications. Use it to `}<strong parentName="p">{`bring users' attention`}</strong>{` to something. Consider Facebook for example:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/820c15f5fd745cc2185b558d9e89a878/5d6a0/tabs-facebook.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAANklEQVQI12O4++rr/2ev3/1//fbt/28/fv7//vPX/y/fvv2/9+jp/9sPHv+/C6TvPHwCponBAMY0Sc6nn1hzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "🤔 Something happened, right?",
            "title": "🤔 Something happened, right?",
            "src": "/static/820c15f5fd745cc2185b558d9e89a878/d9199/tabs-facebook.png",
            "srcSet": ["/static/820c15f5fd745cc2185b558d9e89a878/8ff5a/tabs-facebook.png 240w", "/static/820c15f5fd745cc2185b558d9e89a878/e85cb/tabs-facebook.png 480w", "/static/820c15f5fd745cc2185b558d9e89a878/d9199/tabs-facebook.png 960w", "/static/820c15f5fd745cc2185b558d9e89a878/07a9c/tabs-facebook.png 1440w", "/static/820c15f5fd745cc2185b558d9e89a878/5d6a0/tabs-facebook.png 1580w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`🤔 Something happened, right?`}</figcaption>{`
  `}</figure>
    <p>{`They encourage you to read the message you've just received. All you have to do to change the title of a page is setting it this way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`document.title = 'A new title! | Tomek Dev';
`}</code></pre>
    <SimpleTitleChanger mdxType="SimpleTitleChanger" />
    <p>{`Of course, if a user is already on our page we should not distract them with a flashing title. Maybe change it only if the tab is not active?`}</p>
    <p>{`Consider this example, you work on a Video Editing application and people export their movie. The file has to be prepared and it takes time.
You can show the progress on the title if they go to a different tab and announce a success. Try it here:`}</p>
    <ExportTitleChanger mdxType="ExportTitleChanger" />
    <p>{`First things first. How to detect the change of tab's visibility? (I've just used the right keywords there).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`document.addEventListener('visibilitychange', function () {
  console.log(document.hidden);
});
`}</code></pre>
    <p><inlineCode parentName="p">{`document.hidden`}</inlineCode>{` will tell you what's the status and you can modify the behavior accordingly to your needs. When it comes to the example above
it was done this way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{13-22}",
        "{13-22}": true
      }}>{`const originalTitle = document.title;
let isTabHidden = false;
let percentage = 0;

function changeTitle(title) {
  if (isTabHidden) {
    document.title = title;
  } else {
    document.title = originalTitle;
  }
}

const interval = setInterval(() => {
  percentage += 1;

  if (percentage < 100) {
    changeTitle(\`\${percentage}% Preparing video | Tomek Dev\`);
  } else {
    changeTitle('⚬ Your file is ready! | Tomek Dev');
    clearInterval(interval);
  }
}, 100);

document.addEventListener('visibilitychange', function () {
  isTabHidden = document.hidden;
});
`}</code></pre>
    <p>{`Highlighted code is obviously a trick to show how it could work. In this place, you'd put your code that updates the percentage value.
That can come from a backend endpoint that is preparing the video. No matter if that's a WebSocket or regular XHR request.`}</p>
    <Alert mdxType="Alert">
  Did you notice how progress slows down when you go to a different tab? It's
  because I'm faking the export progress via <i>setInterval</i>. The tab is in
  the background so Chrome executes the timing function (<i>setTimeout</i>/
  <i>setInterval</i>) once per second at maximum.
    </Alert>
    <p>{`Eagle-eyed folks may notice that `}<inlineCode parentName="p">{`visibilitychange`}</inlineCode>{` listener is not needed in that example. We can use `}<inlineCode parentName="p">{`document.hidden`}</inlineCode>{` directly in our
`}<inlineCode parentName="p">{`changeTitle`}</inlineCode>{` function. I added it here only to show that such a listener exists ;) It might be useful in some implementations.`}</p>
    <p>{`And do you know who (probably) uses that event? `}<strong parentName="p">{`Github`}</strong>{`. Open two tabs, leave an unsaved comment in
a PR and go to another tab. You should see this:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a12314b375dd283cc34e443eca31bc43/5d6a0/github-draft-comment.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.833333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsSAAALEgHS3X78AAAAQUlEQVQI12M4e+na/0vXb6HgW/ce/n/24uX/V2/e/n/5+g2QfvP/zbv3/99/+vT/9oPHQPzk/91HT//feQihkTEAaSBIsRAKBEQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Yeah, I left some work there. Thanks for reminding me!",
            "title": "Yeah, I left some work there. Thanks for reminding me!",
            "src": "/static/a12314b375dd283cc34e443eca31bc43/d9199/github-draft-comment.png",
            "srcSet": ["/static/a12314b375dd283cc34e443eca31bc43/8ff5a/github-draft-comment.png 240w", "/static/a12314b375dd283cc34e443eca31bc43/e85cb/github-draft-comment.png 480w", "/static/a12314b375dd283cc34e443eca31bc43/d9199/github-draft-comment.png 960w", "/static/a12314b375dd283cc34e443eca31bc43/07a9c/github-draft-comment.png 1440w", "/static/a12314b375dd283cc34e443eca31bc43/5d6a0/github-draft-comment.png 1580w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Yeah, I left some work there. Thanks for reminding me!`}</figcaption>{`
  `}</figure>
    <h2>{`Dynamic === fun`}</h2>
    <p>{`Let me give you a few more examples of how you can make your title cool.`}</p>
    <p>{`These days, you should see a speaker icon 🔊 if the audio is played in a tab. But it wasn't always like that. You can animate an audio playing
in various ways (click to see in action):`}</p>
    <MusicTitleChanger mdxType="MusicTitleChanger" />
    <h3>{`But be careful`}</h3>
    <p>{`Of course, like with every superpower, there is one thing you need to remember. `}<strong parentName="p">{`Don't overuse it.`}</strong>{` Effects like this could
be a very nice addition to your page. It can extend the experience and make it better. But it can also be annoying.
Like a close button running away from your cursor.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Maybe we've just scratched the surface of what we can do with `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` but here are some takeaways for you:`}</p>
    <ul>
      <li parentName="ul">{`Use a short descriptive title.`}</li>
      <li parentName="ul">{`Put your site name after the title describing the page's content.`}</li>
      <li parentName="ul">{`Add a separator between the title's parts. You can go crazy.`}</li>
      <li parentName="ul">{`Use the title's dynamic nature to extend the user experience.`}</li>
      <li parentName="ul">{`Bring users' attention to a tab if something important happened.`}</li>
      <li parentName="ul">{`Don't overuse dynamic techniques to avoid annoying users.`}</li>
    </ul>
    <p>{`The real estate of the browser's top bar is yours now.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1324708560152965125" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="If there is an input, there should be a form" prevLink="/posts/input-and-form" nextTitle="Useful setup that I always use when starting a new project" nextLink="/posts/powerful-start" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      